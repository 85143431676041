import loadable from "../components/common/loader/loadable";
import Loading from "../components/common/loader";
import pMinDelay from "p-min-delay";

const Shop = loadable(() => pMinDelay(import("../pages/shop"), 250), {
  fallback: <Loading />,
});

const ShopTwo = loadable(
  () => pMinDelay(import("../pages/shop/shop-two"), 250),
  {
    fallback: <Loading />,
  }
);

const ShopList = loadable(
  () => pMinDelay(import("../pages/shop/shop-list"), 250),
  {
    fallback: <Loading />,
  }
);

const ShopLeftSideBar = loadable(
  () => pMinDelay(import("../pages/shop/shop-left-sidebar"), 250),
  {
    fallback: <Loading />,
  }
);

const ShopRigthSideBar = loadable(
  () => pMinDelay(import("../pages/shop/shop-right-sidebar"), 250),
  {
    fallback: <Loading />,
  }
);

const shopRoutes = [
  {
    id: "shop",
    path: "/shop",
    component: Shop,
    exact: true,
    isProtectedRoute: false,
  },
  {
    id: "shop-two",
    path: "/shopTwo",
    component: ShopTwo,
    exact: true,
    isProtectedRoute: false,
  },
  {
    id: "shop-list",
    path: "/shoplist",
    component: ShopList,
    exact: true,
    isProtectedRoute: false,
  },
  {
    id: "shop-left-sidebar",
    path: "/shop-left-bar",
    component: ShopLeftSideBar,
    exact: true,
    isProtectedRoute: false,
  },
  {
    id: "shop-right-sidebar",
    path: "/shop-right-bar",
    component: ShopRigthSideBar,
    exact: true,
    isProtectedRoute: false,
  },
];

export default shopRoutes;
