import loadable from "../components/common/loader/loadable";
import Loading from "../components/common/loader";
import pMinDelay from "p-min-delay";

const Accounts = loadable(
  () => pMinDelay(import("../pages/accounts/Accounts"), 250),
  {
    fallback: <Loading />,
  }
);

const AddBook = loadable(
  () => pMinDelay(import("../pages/librarian/AddBook"), 250),
  {
    fallback: <Loading />,
  }
);

const LibrarianProfile = loadable(
  () => pMinDelay(import("../pages/librarian/LibrarianProfile"), 250),
  {
    fallback: <Loading />,
  }
);

const LibrarianBookRented = loadable(
  () => pMinDelay(import("../pages/librarian/BookRented"), 250),
  {
    fallback: <Loading />,
  }
);

const LibrarianListedBooks = loadable(
  () => pMinDelay(import("../pages/librarian/ListedBooks"), 250),
  {
    fallback: <Loading />,
  }
);

const librarianRoutes = [
  {
    id: "librarian",
    path: "accounts/:options",
    component: Accounts,
    isAppRoute: true,
    exact: true,
    isProtectedRoute: true,
  },
  {
    id: "addBook",
    path: "/librarian/add-book",
    component: AddBook,
    isAppRoute: true,
    exact: true,
    isProtectedRoute: true,
  },
  {
    id: "librarianProfile",
    path: "/librarian/librarian-profile",
    component: LibrarianProfile,
    isAppRoute: true,
    exact: true,
    isProtectedRoute: true,
  },
  {
    id: "bookRented",
    path: "/librarian/book-rented",
    component: LibrarianBookRented,
    isAppRoute: true,
    exact: true,
    isProtectedRoute: true,
  },
  {
    id: "bookRented",
    path: "/librarian/listed-books",
    component: LibrarianListedBooks,
    isAppRoute: true,
    exact: true,
    isProtectedRoute: true,
  },
];

export default librarianRoutes;
