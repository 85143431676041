import { Fragment, useRef, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  RiSearchLine,
  RiUserLocationFill,
  RiUserLine,
  RiSettings2Line,
} from "react-icons/ri";
import { IoIosArrowDown } from "react-icons/io";
import { FiLogOut } from "react-icons/fi";
import cn from "classnames";
import { Button, Col, Dropdown, Row } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { logoutUser } from "../../../app/slices/user";
import menuData from "./MenuData";
import logo from "../../../assets/img/logo.png";
import useOnClickOutsize from "../../../utils/useOnClickOutsize";
import defaultBookImage from "../../../assets/img/book/book.png";

const Header = () => {
  const { pathname } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const categoryWrapperRef = useRef(null);
  const user = useSelector((state) => state.user);
  const categoryList = useSelector((state) => state.category.category).slice(
    0,
    18
  );
  const [isCategoryListDisplayed, setIsCategoryListDisplayed] = useState(false);

  useOnClickOutsize(categoryWrapperRef, (value) =>
    setIsCategoryListDisplayed(value)
  );

  const logout = () => {
    dispatch(logoutUser());
    history.push("/login");
  };

  return (
    <div
      className="w-100 position-fixed bg-white pt-1"
      style={{ zIndex: "10" }}
    >
      <div className="container d-flex align-items-center justify-content-between py-3">
        <Link to="/">
          <img src={logo} alt="logo" width={100} />
        </Link>
        <div className="d-flex" style={{ gap: "80px" }}>
          {menuData.map((menu) => {
            if (menu.id === "category" && menu.isDropdown) {
              return (
                <Fragment key={menu.id}>
                  <p
                    to={menu.path}
                    className={cn("h4 p-0 m-0 pb-1 menu-item cursor-pointer", {
                      "active-menu-item ": menu.path === pathname,
                      "inactive-menu-item": menu.path !== pathname,
                    })}
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsCategoryListDisplayed(!isCategoryListDisplayed);
                    }}
                  >
                    {menu.name}
                    <IoIosArrowDown
                      size={20}
                      className="cursor-pointer text-secondary"
                    />
                  </p>
                  <div
                    ref={categoryWrapperRef}
                    className={cn(
                      "position-absolute w-100 mx-auto bg-white shadow p-4",
                      {
                        "d-none": !isCategoryListDisplayed,
                        "d-block": isCategoryListDisplayed,
                      }
                    )}
                    style={{
                      left: 0,
                      right: 0,
                      top: "90px",
                      maxWidth: "900px",
                      borderRadius: "5px",
                    }}
                  >
                    <div className="d-flex p-1">
                      <div className="w-100 w-md-50">
                        <Row>
                          {categoryList.map((category, i) => (
                            <Col
                              xs={6}
                              key={category._id}
                              className={cn({ "mt-3": i >= 2 })}
                            >
                              <Link
                                to={`/books-by-category/${category._id}`}
                                className="text-grey-200 text-capitalize p-0 m-0 text-16"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setIsCategoryListDisplayed(false);
                                }}
                                style={{ fontWeight: "500" }}
                              >
                                {category.name}
                              </Link>
                            </Col>
                          ))}
                        </Row>
                        <div className="w-100 text-center mt-4">
                          <Link
                            to="/books-by-category/all"
                            className="text-grey-100 text-18"
                            onClick={(e) => {
                              e.stopPropagation();
                              setIsCategoryListDisplayed(false);
                            }}
                            style={{ fontWeight: "500" }}
                          >
                            View All
                          </Link>
                        </div>
                      </div>
                      <div className="text-right d-none d-md-block w-50">
                        <img
                          src={defaultBookImage}
                          alt="book"
                          style={{ height: "21.5rem" }}
                        />
                      </div>
                    </div>
                  </div>
                </Fragment>
              );
            }

            return (
              <Link
                key={menu.id}
                to={menu.path}
                className={cn("h4 p-0 m-0 pb-1 menu-item", {
                  "active-menu-item ": menu.path === pathname,
                  "inactive-menu-item": menu.path !== pathname,
                })}
              >
                {menu.name}
              </Link>
            );
          })}
        </div>

        <div>
          {user.status ? (
            <div className="d-flex align-items-center" style={{ gap: "30px" }}>
              <Link to="/search">
                <RiSearchLine size={28} color="#807F7F" />
              </Link>

              <div
                className="d-flex align-items-center"
                style={{ gap: "20px" }}
              >
                <Link to="/accounts/customer-order">
                  <div
                    className="rounded-circle bg-light"
                    style={{ padding: "10px" }}
                  >
                    <RiUserLocationFill size={30} className="text-primary" />
                  </div>
                </Link>

                <div>
                  <p className="text-capitalize" style={{ fontSize: "1.2rem" }}>
                    {user.user.firstName}
                  </p>
                  <p className="text-capitalize" style={{ fontSize: "1.2rem" }}>
                    {user.user.lastName}
                  </p>
                </div>
                <Dropdown>
                  <Dropdown.Toggle
                    className="bg-transparent border-0"
                    variant="link"
                    bsPrefix="p-0"
                  >
                    <IoIosArrowDown
                      size={30}
                      className="cursor-pointer text-grey-200"
                    />
                  </Dropdown.Toggle>

                  <Dropdown.Menu
                    className="border-0 shadow mt-5"
                    style={{ padding: "35px 25px", borderRadius: "10px" }}
                  >
                    <Dropdown.Item className="p-2">
                      <Link to="/accounts/customer-order">
                        <div
                          className="d-flex align-items-center p-2"
                          style={{ gap: "20px", width: "380px" }}
                        >
                          <RiUserLine size={30} className="text-secondary" />
                          <p className="h4">Profile</p>
                        </div>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item className="mt-3 p-2">
                      <div
                        className="d-flex align-items-center p-2"
                        style={{ gap: "20px", width: "380px" }}
                      >
                        <RiSettings2Line size={30} className="text-secondary" />
                        <p className="h4">Account Setting</p>
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Item className="mt-3 p-2" onClick={logout}>
                      <div
                        className="d-flex align-items-center p-2"
                        style={{ gap: "20px", width: "380px" }}
                      >
                        <FiLogOut size={30} className="text-primary" />
                        <p className="h4 text-primary">Logout</p>
                      </div>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          ) : (
            <div className="d-flex align-items-center" style={{ gap: "30px" }}>
              <Link to="/search">
                <RiSearchLine size={28} color="#807F7F" />
              </Link>
              <Link
                to="/register"
                className="bg-transparent outline-none border-0 user-select-none cursor-pointer"
                style={{ fontSize: "18px", fontWeight: 500, color: "#807F7F" }}
              >
                Register
              </Link>
              <Link to="/login">
                <Button
                  className="btn-primary text-white"
                  style={{
                    fontWeight: "500",
                    fontSize: "18px",
                    lineHeight: "27px",
                    padding: "12px 28px",
                    borderRadius: "12px",
                  }}
                >
                  Login
                </Button>
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
