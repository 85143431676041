import { createSlice } from "@reduxjs/toolkit";
import {
  setUserLoginData,
  logout,
  isUserLoggedIn,
  getUserData,
} from "../../utils/utils";
import { getAuthLocation } from "../../utils/utils.js";

// User Slice
const userSlice = createSlice({
  name: "user",
  initialState: {
    status: isUserLoggedIn(),
    user: getUserData(),
  },
  reducers: {
    loginUser: (state, action) => {
      const { userData, token, isRememberMe = false } = action.payload;
      setUserLoginData({ userData, token, isRememberMe });
      state.user = userData;
      state.status = true;
    },

    logoutUser: (state) => {
      logout();
      state.status = false;
      state.user = {};
    },
    setUserData: (state, action) => {
      const { userData = null } = action.payload;
      const authLocation = getAuthLocation();
      if (!authLocation || !userData) return;
      setUserLoginData({
        userData,
        isRememberMe: authLocation === "LOCAL_STORAGE" ? true : false,
      });
      state.user = userData;
    },
  },
});

export const { loginUser, logoutUser, setUserData } = userSlice.actions;
export default userSlice.reducer;
