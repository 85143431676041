import loadable from "../components/common/loader/loadable";
import Loading from "../components/common/loader";
import pMinDelay from "p-min-delay";

const Home = loadable(() => pMinDelay(import("../pages"), 250), {
  fallback: <Loading />,
});

const homeRoutes = [
  {
    id: "home",
    path: "/",
    component: Home,
    exact: true,
    isAppRoute: true,
    isProtectedRoute: false,
  },
];

export default homeRoutes;
