import loadable from "../components/common/loader/loadable";
import Loading from "../components/common/loader";
import pMinDelay from "p-min-delay";

const Login = loadable(
  () => pMinDelay(import("../pages/authentication/Login"), 250),
  {
    fallback: <Loading />,
  }
);

const ForgotPassword = loadable(
  () => pMinDelay(import("../pages/authentication/ForgotPassword"), 250),
  {
    fallback: <Loading />,
  }
);

const ForgotPasswordVerifyOtp = loadable(
  () =>
    pMinDelay(import("../pages/authentication/ForgotPasswordVerifyOtp"), 250),
  {
    fallback: <Loading />,
  }
);

const Register = loadable(
  () => pMinDelay(import("../pages/authentication/Register"), 250),
  {
    fallback: <Loading />,
  }
);

const RegisterVerifyOtp = loadable(
  () => pMinDelay(import("../pages/authentication/RegisterVerifyOtp"), 250),
  {
    fallback: <Loading />,
  }
);

const authenticationRoutes = [
  {
    id: "register",
    path: "/register",
    component: Register,
    exact: true,
    isAppRoute: true,
    isProtectedRoute: false,
  },

  {
    id: "login",
    path: "/login",
    exact: true,
    isAppRoute: true,
    isProtectedRoute: false,
    component: Login,
  },

  {
    id: "forgot-password",
    path: "/forgot-password",
    exact: true,
    component: ForgotPassword,
    isAppRoute: true,
    isProtectedRoute: false,
  },

  {
    id: "forgot-password-verify-otp",
    path: "/forgot-password-verify-otp",
    exact: true,
    component: ForgotPasswordVerifyOtp,
    isAppRoute: true,
    isProtectedRoute: false,
  },

  {
    id: "register-verify-otp",
    path: "/register-verify-otp",
    exact: true,
    component: RegisterVerifyOtp,
    isAppRoute: true,
    isProtectedRoute: false,
  },
];

export default authenticationRoutes;
