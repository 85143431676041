const TOKEN_KEY = "token";
const USER_DATA_KEY = "user";

export function setUserLoginData(props) {
  const { userData = null, token = null, isRememberMe = false } = props;

  if (isRememberMe) {
    if (!userData) return;
    if (token) localStorage.setItem(TOKEN_KEY, token);
    localStorage.setItem(USER_DATA_KEY, JSON.stringify(userData));
    return;
  }

  if (token) sessionStorage.setItem(TOKEN_KEY, token);
  sessionStorage.setItem(USER_DATA_KEY, JSON.stringify(userData));
}

export function logout() {
  localStorage.clear();
  sessionStorage.clear();
}

export function isUserLoggedIn() {
  if (localStorage.getItem(TOKEN_KEY)) return true;
  if (sessionStorage.getItem(TOKEN_KEY)) return true;
  logout();
  return false;
}

export function getUserData() {
  if (!isUserLoggedIn()) {
    logout();
    return null;
  }

  const localStorageUserData = localStorage.getItem(USER_DATA_KEY);
  const sessionStorageUserData = sessionStorage.getItem(USER_DATA_KEY);

  try {
    if (localStorageUserData) {
      return JSON.parse(localStorageUserData);
    }
    return JSON.parse(sessionStorageUserData);
  } catch {
    logout();
    return null;
  }
}

export function getToken() {
  const localStorageToken = localStorage.getItem(TOKEN_KEY);
  const sessionStorageToken = sessionStorage.getItem(TOKEN_KEY);

  if (localStorageToken) return localStorageToken;
  if (sessionStorageToken) return sessionStorageToken;

  return null;
}

export function getAuthLocation() {
  const localStorageToken = localStorage.getItem(TOKEN_KEY);
  const sessionStorageToken = sessionStorage.getItem(TOKEN_KEY);
  if (localStorageToken) return "LOCAL_STORAGE";
  else if (sessionStorageToken) return "SESSION_STORAGE";
  return null;
}
