const menuData = [
  { id: "home", name: "HOME", path: "/" },
  {
    id: "category",
    name: "CATEGORY",
    path: "/book-by-category/all",
    isDropdown: true,
  },
  { id: "shop", name: "SHOP", path: "/shop" },
  { id: "library", name: "LIBRARY", path: "/library" },
];

export default menuData;
