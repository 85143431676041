import loadable from "../components/common/loader/loadable";
import Loading from "../components/common/loader";
import pMinDelay from "p-min-delay";

const Accounts = loadable(
  () => pMinDelay(import("../pages/accounts/Accounts"), 250),
  {
    fallback: <Loading />,
  }
);

const MyAccounts = loadable(
  () => pMinDelay(import("../pages/myAccount"), 250),
  {
    fallback: <Loading />,
  }
);

const CustomerOrder = loadable(
  () => pMinDelay(import("../pages/myAccount/CustomerOrder"), 250),
  {
    fallback: <Loading />,
  }
);
const CustomerDownload = loadable(
  () => pMinDelay(import("../pages/myAccount/CustomerDownloads"), 250),
  {
    fallback: <Loading />,
  }
);

const CustomerAddress = loadable(
  () => pMinDelay(import("../pages/myAccount/CustomerAddress"), 250),
  {
    fallback: <Loading />,
  }
);

const CustomerAccountDetails = loadable(
  () => pMinDelay(import("../pages/myAccount/CustomerAccountDetails"), 250),
  {
    fallback: <Loading />,
  }
);

const LibraryMembership = loadable(
  () => pMinDelay(import("../pages/myAccount/LibraryMembership"), 250),
  {
    fallback: <Loading />,
  }
);

const accountRoutes = [
  {
    id: "my-account",
    path: "/my-account",
    component: MyAccounts,
    isAppRoute: true,
    exact: true,
    isProtectedRoute: true,
  },
  {
    id: "customer-order",
    path: "/accounts/:options/",
    component: Accounts,
    isAppRoute: true,
    exact: true,
    isProtectedRoute: true,
  },
  {
    id: "customer-downloads",
    path: "/my-account/customer-download",
    component: CustomerDownload,
    isAppRoute: true,
    exact: true,
    isProtectedRoute: true,
  },
  {
    id: "customer-address",
    path: "/my-account/customer-address",
    component: CustomerAddress,
    isAppRoute: true,
    exact: true,
    isProtectedRoute: true,
  },

  {
    id: "customer-account-details",
    path: "/my-account/customer-account-details",
    component: CustomerAccountDetails,
    isAppRoute: true,
    exact: true,
    isProtectedRoute: true,
  },

  {
    id: "library-membership",
    path: "/my-account/library-membership",
    component: LibraryMembership,
    isAppRoute: true,
    exact: true,
    isProtectedRoute: true,
  },
];

export default accountRoutes;
