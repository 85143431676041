import { Fragment } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  BrowserRouter,
} from "react-router-dom";
import pMinDelay from "p-min-delay";
import loadable from "../components/common/loader/loadable";
import Loading from "../components/common/loader";
import ProtectedRoute from "../components/protectedRoute/ProtectedRoute";
import AppLayout from "../layout/AppLayout";
import authenticationRoutes from "./authenticationRoutes";
import accountRoutes from "./accountRoutes";
import libraryRoutes from "./libraryRoutes";
import shopRoutes from "./shopRoutes";
import homeRoutes from "./homeRoutes";
import vendorRoutes from "./vendorRoutes";
import bookRoutes from "./bookRoutes";
import librarianRoutes from "./librarianRoutes";
import searchRoutes from "./searchRoutes";

const Error = loadable(() => pMinDelay(import("../pages/Error"), 250), {
  fallback: <Loading />,
});

const ScrollToTop = loadable(
  () => pMinDelay(import("../components/common/ScrollToTop"), 250),
  { fallback: <Loading /> }
);

const MergeLayoutRoute = (props) => {
  const { children, route } = props;

  const AppLayoutWrapper = route.isAppRoute ? AppLayout : Fragment;
  const PrivateRouteWrapper = route.isProtectedRoute
    ? ProtectedRoute
    : Fragment;

  return (
    <AppLayoutWrapper>
      <PrivateRouteWrapper>{children}</PrivateRouteWrapper>
    </AppLayoutWrapper>
  );
};

const CustomRouter = () => {
  const allRoutes = [
    ...authenticationRoutes,
    ...accountRoutes,
    ...libraryRoutes,
    ...shopRoutes,
    ...homeRoutes,
    ...vendorRoutes,
    ...bookRoutes,
    ...librarianRoutes,
    ...searchRoutes,
  ];

  return (
    <BrowserRouter>
      <Router>
        <ScrollToTop />
        <Switch>
          {allRoutes.map((route) => (
            <Route
              path={route.path}
              exact={route.exact}
              key={route.id}
              render={() => (
                <MergeLayoutRoute route={route}>
                  <route.component />
                </MergeLayoutRoute>
              )}
            />
          ))}

          <Route exact component={Error} />
        </Switch>
      </Router>
    </BrowserRouter>
  );
};

export default CustomRouter;
