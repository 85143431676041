import http from "../../utils/http";

const getAllCategoryForDropdownApi = async () => {
  const categoryResponse = await http.get("/category", {
    params: {
      forDropDown: true,
    },
  });
  return categoryResponse.data;
};

export default getAllCategoryForDropdownApi;
