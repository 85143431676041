import loadable from "../components/common/loader/loadable";
import Loading from "../components/common/loader";
import pMinDelay from "p-min-delay";

const Library = loadable(() => pMinDelay(import("../pages/library"), 250), {
  fallback: <Loading />,
});

const MembershipPricing = loadable(
  () => pMinDelay(import("../pages/library/MemberShipPricing"), 250),
  {
    fallback: <Loading />,
  }
);

const PaySelectedMemberShip = loadable(
  () => pMinDelay(import("../pages/library/PaySelectedMemberShip"), 250),
  {
    fallback: <Loading />,
  }
);
const BorrowedBooks = loadable(
  () => pMinDelay(import("../pages/library/BorrowedBooks"), 250),
  {
    fallback: <Loading />,
  }
);

const libraryRoutes = [
  {
    id: "library",
    path: "/library",
    component: Library,
    exact: true,
    isProtectedRoute: false,
  },
  {
    id: "membershipPricing",
    path: "/library/pricing",
    component: MembershipPricing,
    exact: true,
    isProtectedRoute: false,
  },
  {
    id: "paySelectedMembership",
    path: "/library/pricing/pay/:id",
    component: PaySelectedMemberShip,
    exact: true,
    isProtectedRoute: false,
  },
  {
    id: "rentBooks",
    path: "/library/borrowed-books",
    component: BorrowedBooks,
    exact: true,
    isProtectedRoute: false,
  },
];

export default libraryRoutes;
