import logo from "../../../assets/img/logo.png";

const Loader = () => {
  return (
    <>
      <div className="loader_wrapper">
        <img width="200px" src={logo} alt="loader" />
      </div>
    </>
  );
};

export default Loader;
