import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const ProtectedRoute = (props) => {
  const { children } = props;
  const history = useHistory();
  const status = useSelector((state) => state.user.status);

  useEffect(() => {
    if (!status) history.push("/login");
  }, [status, history]);

  if (status) {
    return <div>{children}</div>;
  }

  return <div />;
};

export default ProtectedRoute;
