import loadable from "../components/common/loader/loadable";
import Loading from "../components/common/loader";
import pMinDelay from "p-min-delay";

const Accounts = loadable(
  () => pMinDelay(import("../pages/accounts/Accounts"), 250),
  {
    fallback: <Loading />,
  }
);

const AllProduct = loadable(
  () => pMinDelay(import("../pages/vendor/AllProduct"), 250),
  {
    fallback: <Loading />,
  }
);

const AllOrder = loadable(
  () => pMinDelay(import("../pages/vendor/allOrder"), 250),
  {
    fallback: <Loading />,
  }
);

const VendorProfile = loadable(
  () => pMinDelay(import("../pages/vendor/VendorProfile"), 250),
  {
    fallback: <Loading />,
  }
);

const AddProducts = loadable(
  () => pMinDelay(import("../pages/vendor/AddProducts"), 250),
  {
    fallback: <Loading />,
  }
);

const VendorSetting = loadable(
  () => pMinDelay(import("../pages/vendor/VendorSetting"), 250),
  {
    fallback: <Loading />,
  }
);

const vendorRoutes = [
  {
    id: "vendor",
    path: "accounts/:options",
    component: Accounts,
    isAppRoute: true,
    exact: true,
    isProtectedRoute: false,
  },
  {
    id: "allProduct",
    path: "/vendor/all-product",
    component: AllProduct,
    isAppRoute: true,
    exact: true,
    isProtectedRoute: false,
  },
  {
    id: "allOrder",
    path: "/vendor/all-order",
    component: AllOrder,
    isAppRoute: true,
    exact: true,
    isProtectedRoute: false,
  },
  {
    id: "vendorProfile",
    path: "/vendor/vendor-profile",
    component: VendorProfile,
    isAppRoute: true,
    exact: true,
    isProtectedRoute: false,
  },
  {
    id: "addProduct",
    path: "/vendor/add-products",
    component: AddProducts,
    isAppRoute: true,
    exact: true,
    isProtectedRoute: true,
  },
  {
    id: "vendorSetting",
    path: "/vendor/vendor-setting",
    component: VendorSetting,
    isAppRoute: true,
    exact: true,
    isProtectedRoute: false,
  },
];

export default vendorRoutes;
