import loadable from "../components/common/loader/loadable";
import Loading from "../components/common/loader";
import pMinDelay from "p-min-delay";

const SearchBooks = loadable(
  () => pMinDelay(import("../pages/SearchBooks"), 250),
  {
    fallback: <Loading />,
  }
);

const searchRoutes = [
  {
    id: "search",
    path: "/search",
    component: SearchBooks,
    exact: true,
    isAppRoute: true,
    isProtectedRoute: false,
  },
];

export default searchRoutes;
