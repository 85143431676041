import "./app.css";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import CustomRouter from "./router";
import toast, { Toaster } from "react-hot-toast";
import { setCategory } from "./app/slices/category";
import getAllCategoryForDropdownApi from "./services/categories/getAllCategoryDropDownApi";

const App = () => {
  const dispatch = useDispatch();

  const setCategoryData = async () => {
    try {
      const cateResData = await getAllCategoryForDropdownApi();
      dispatch(setCategory(cateResData?.data || []));
    } catch (error) {
      toast.error(
        error?.response?.data?.error ||
          "Facing an issue while loading category data"
      );
    }
  };

  useEffect(() => {
    setCategoryData();
  }, []);

  return (
    <div>
      <CustomRouter />
      <Toaster position="top-right" />
    </div>
  );
};

export default App;
