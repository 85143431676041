import cn from "classnames";
import Header from "../components/common/Header";
import Footer from "../components/common/Footer";

const AppLayout = (props) => {
  const { children, className = "", ...others } = props;

  return (
    <div className={cn(className)} {...others}>
      <Header />
      <div style={{ paddingTop: "100px" }}>{children}</div>
      <Footer />
    </div>
  );
};

export default AppLayout;
