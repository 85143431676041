import loadable from "../components/common/loader/loadable";
import Loading from "../components/common/loader";
import pMinDelay from "p-min-delay";

const BookDetails = loadable(
  () => pMinDelay(import("../pages/books/BookDetails"), 250),
  {
    fallback: <Loading />,
  }
);

const BestBooks = loadable(
  () => pMinDelay(import("../pages/books/BestBooks"), 250),
  {
    fallback: <Loading />,
  }
);

const BooksByCategory = loadable(
  () => pMinDelay(import("../pages/books/BooksByCategory"), 250),
  {
    fallback: <Loading />,
  }
);

const bookRoutes = [
  {
    id: "bookDetails",
    path: "/book/:bookId/:options",
    component: BookDetails,
    exact: true,
    isAppRoute: true,
    isProtectedRoute: false,
  },
  {
    id: "bestBooks",
    path: "/books-by-type/:bookType",
    component: BestBooks,
    exact: true,
    isAppRoute: true,
    isProtectedRoute: false,
  },
  {
    id: "booksByCategory",
    path: "/books-by-category/:categoryId",
    component: BooksByCategory,
    exact: true,
    isAppRoute: true,
    isProtectedRoute: false,
  },
];

export default bookRoutes;
